
// import { i18n } from '@/i18n';

export default {
  name: 'app-terms-and-conditions-page',

  methods: {
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
  },
};
